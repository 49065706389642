@import url('https://fonts.googleapis.com/css2?family=Maven+Pro&family=Roboto:ital,wght@0,300;0,500;1,300&display=swap');

#logo {
  font-family: 'Maven Pro', sans-serif;
  text-align: center;
    margin: auto;
    border-bottom: 1px solid currentColor;
}

.arbejdsbskrivelser {
  & .ant-select-selection-overflow-item {
    flex: 1 0 100% !important;

    & .ant-select-selection-item {
      justify-content: space-between;
      background: #f6ffed;
      border: 1px solid #b7eb8f;
    }
  }
}
